import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import Icon from "../components/icon.tsx";
import AdvDis from "../components/adv-dis";
import Breadcrumbs from "../components/breadcrumbs"
import Out from "../components/out-product";
import Translate from "../components/translate";
import scrollTo from "gatsby-plugin-smoothscroll";
import useDescription from "../hooks/useDescription";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import resolveProductUrl from "../utils/urls/resolve-product-url";
import resolveLocalfirmUrl from "../utils/urls/resolve-localfirm-url";
import Layout from "../components/layout";
import resolveUrl from "../utils/urls/resolve-url";
import useMedia from "../hooks/useMedia";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/bank/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const Bank = ({
  data: { mysqlBank, allMysqlLocalfirms, faq, allMysqlComments, allMysqlDescriptionBank, bankPromotions, allSitePage },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const {
    advantages,
    description_advantages,
    disadvantages,
    swift,
    mysqlImage,
    name,
    address,
    rating,
    accounts,
    card,
    credits,
    capital,
    established_year,
    workhours,
    app,
    phone,
    number_of_establishments,
    owner,
    mysqlId,
    url,
    login_url,
    inactive_reason,
  } = mysqlBank;

  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  let idTimeout = null;

  const scroll = (id) => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)
  const d = useDescription(allMysqlDescriptionBank.nodes);
  const bankPromoUrl = allSitePage.nodes.filter(page => page.path.includes(resolveUrl(url)))[0]?.path

  return (
    <Layout pathname={pathname}>
      <div className={["bank-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")} />
      <div className={[b.container, 'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className="firm-page">
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.mb0].join(" ")}>
                  {name} - informacje o banku i produktach
                </h1>
              </div>
              <div className={[b.colLg4,b.mbLg5,b.pbLg5, b.orderLgLast].join(' ')}>
                {mysqlImage && (
                  <div className={["bank-box", 'sticky-box'].join(" ")}>
                    {inactive_reason && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
                      <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                      <p className={['redToWhite', 'font-size-13',b.my0, b.py0,'tp-name'].join(' ')} >{inactive_reason}</p>
                    </div>}

                    <div className={["bank-padding-box", inactive_reason ? b.pt3 : ''].join(' ')}>
                      <div className='bank-padding-img'>
                        <Thumbnail image={mysqlImage} alt={name} />
                      </div>
                      <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                        <div className="stars-container">
                          {rating > 0 && (
                            <div className="star-box" style={{ width: `${rating}% ` }}>
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" />
                            </div>
                          )}
                          <div className='empty-star-box'>
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" />
                          </div>
                        </div>
                      </div>
                      <span className={[b.dFlex, b.justifyContentCenter, 'blackToWhite', 'font-size-20'].join(" ")}>
                        {(rating > 0 ? rating / 20 : 0).toFixed(1)}
                      </span>
                    </div>
                    <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                      <Icon size={20} icon="Logout" className={[b.mr1, "icon-blog"].join(" ")} />
                      <Translate tKey="Add opinion" />
                    </button>
                    <Out className={[b.btn, "btn-success", "btn-cta", b.textWhite,
                    b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.fontWeightNormal].join(' ')}
                      defaultDescription="Contact form" item={mysqlBank} defaultIcon="Pen" />
                  </div>
                )}
                {(credits.length || accounts.length || card) && <div className={["local-firm-box-1", 'sticky-box2'].join(' ')}>
                  <h3><Translate tKey="Products" /> {name}</h3>
                  {credits && (
                    credits.map(({ type, name, ...credit }, i) => (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                        <Link to={resolveProductUrl({ bank: mysqlBank, ...credit })}>
                          <div className={[b.dFlex, b.mr3].join(" ")}>
                            {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                          </div>
                        </Link>
                        <Out item={credit} withoutText />
                      </div>
                    ))
                  )}
                  {accounts && (
                    accounts.map(({ type, name, ...account }, i) => (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                        <Link to={resolveProductUrl({ bank: mysqlBank, ...account })}>
                          <div className={[b.dFlex, b.mr3].join(' ')}>
                            {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            {type === 7 ? <Icon size={40} icon="Premium-acc" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                          </div>
                        </Link>
                        <Out item={account} withoutText />
                      </div>
                    ))
                  )}
                  {card && (
                    <div className={[b.dFlex, b.justifyContentBetween].join(' ')}>
                      <Link to={resolveProductUrl({ bank: mysqlBank, ...card })}>
                        <div className={[b.dFlex, b.mr3].join(" ")}>
                          <Icon size={40} icon="Credit-cards" className={[b.mr2, "icon-blog"].join(" ")} />
                          <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{card.name}</p>
                        </div>
                      </Link>
                      <Out item={card} withoutText />
                    </div>
                  )}
                </div>}
              </div>
              <div className={b.colLg8}>
                {d.lead && d.lead.description && (
                  <div className={['blackToWhite', 'font-size-18', b.fontWeightNormal].join(' ')} dangerouslySetInnerHTML={{ __html: d.lead.description }} />
                )}
                <div className={["bank-box", 'bank-box-relative'].join(' ')}>
                  <div className={['font-size-20', b.mb3,'blackToWhite'].join(' ')}><Translate tKey="Info about bank" /> {name}</div>
                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="HQ" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Point address" /></small>
                          <p className='firm-box-text'>{address || '-'}</p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Bags-of-cash" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="The amount of assets" /></small>
                          <p className='firm-box-text'>{capital ? <Translate tKey="cu">{(text) => `${capital} ${text}`}</Translate> : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="License" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Established year" /></small>
                          <p className='firm-box-text'>
                            {established_year || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Flags" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Who does the bank belong to?" /></small>
                          <p className='firm-box-text'>{owner  || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Work-hours" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Opening hours" /></small>
                          <p className='firm-box-text'>{workhours || '-'}</p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Number of branches" /></small>
                          <p className='firm-box-text'>{number_of_establishments || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Helpline" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Helpline - individual customers" /></small>
                          <p className='firm-box-text'>{phone|| '-'}</p>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Bank-building" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="BIC/Swift code" /></small>
                          <p className='firm-box-text'>{swift || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={b.row}>
                    <div className={[b.colMd6]}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Cell-phone" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Mobile app" /></small>
                          {app && app.url ? (<>
                            <Link to={`/banki/${url}/${app.url}/`}>
                              <p className={['firm-box-text', b.fontWeightNormal].join(' ')}>
                                {app.name}
                              </p>
                            </Link>
                          </>) : <p className='firm-box-text'>-</p>}
                        </div>
                      </div>
                    </div>
                    {login_url &&
                      (<>
                        <div className={[b.colMd6]}>
                          <div className={b.dFlex}>
                            <Icon size={40} icon="WWW" className={[b.mr2, "icon-blog"].join(" ")} />
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Website address" /></small>
                              <p className='firm-box-text'>
                                <span className={["blueToWhite", b.fontWeightNormal].join(' ')}>{login_url}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>)}
                    {bankPromotions.totalCount > 0 && bankPromoUrl &&
                      <div className={[b.colMd12, b.mt3].join(' ')}>
                        <Link to={bankPromoUrl} className={['account-promotions-btn', b.py1, b.px2, b.dFlex, b.justifyContentBetween, b.alignItemsCenter, 'cursor-pointer', b.fontWeightNormal].join(' ')}>
                          <div>
                            <Icon size={40} icon="Coins" className={[b.mr2, "icon-blog"].join(" ")} />
                            Wszystkie promocje {name}
                          </div>
                          {!isMobileResolution ?
                            <div>
                              Sprawdź
                              <Icon size={20} icon="Angle-right" className={[b.ml1, "icon-blog"].join(" ")} />
                            </div>
                            : ''
                          }
                        </Link>
                      </div>
                    }
                  </div>
                </div>

                {d.elixir && d.elixir.description && (
                  <>
                    <h2>{d.elixir.header}</h2>
                    <BlogContent content={d.elixir.description} />
                  </>
                )}

                {advantages && disadvantages && (
                  <div className="bank">
                    <AdvDis advantages={advantages} disadvantages={disadvantages}>
                      <h2><Translate tKey="Adv and disadv" /> {name}</h2>

                      {description_advantages && <BlogContent content={description_advantages} className='blueToGray' />}
                    </AdvDis>
                  </div>
                )}

                {faq?.nodes.length > 0 && (
                  <>
                    <h2><Translate tKey="The most important information about" /> {name}</h2>
                    
                    {faq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}

                    <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                      <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                        <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Ask your own question" />
                      </button>
                    </div>
                  </>
                )}
                {allMysqlLocalfirms.group.length > 0 && (
                  <>
                    {d?.institutions?.header && <h2>{d.institutions.header}</h2>}
                    {d?.institutions?.description && <BlogContent content={d.institutions.description} />}

                    <div className={"bank-box"}>
                      <div className={b.row}>
                        {allMysqlLocalfirms.group.map(({ nodes }) =>
                          nodes.map((node, i) => (
                            <div className={[b.colMd4, b.dFlex, "blackToWhite", b.my2,].join(" ")} key={i}>
                              <Icon size={40} icon="Branches" className={["icon-blog", b.mr2].join(" ")} />
                              <Link to={resolveLocalfirmUrl({ bank: mysqlBank, ...node })} className={b.fontWeightNormal}>
                                <p className={[b.pt2, b.textCapitalize].join(' ')}>
                                  {name} {node.address_city}
                                </p>
                              </Link>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </>
                )}

                <Comments items={allMysqlComments.nodes} title="standard" itemName={name} formID={mysqlId} commentType="bank" />
                <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="bank" faq={isFaq} />
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Bank;

export const query = graphql`
  query QueryBank($mysqlId: Int!, $url: String!, $promoUrl: String!) {
    allMysqlDescriptionBank(filter: { bank_id: { eq: $mysqlId }}) {
      nodes {
        header
        description
        name
      }
    }
    mysqlBank: mysqlBank(mysqlId: { eq: $mysqlId }) {
      description
      address
      advantages
      description_advantages
      disadvantages
      domain
      swift
      rating
      workhours
      phone
      address_description
      capital
      name
      prefix_url
      specific_url
      number_of_establishments
      url
      login_url
      established_year
      owner
      mysqlId
      inactive_reason
      app {
        url
        name
      }
      accounts {
        url
        prefix_url
        specific_url
        name
        type
        wniosker_id
      }
      credits {
        url
        prefix_url
        specific_url
        type
        name
        wniosker_id
      }
      card {
        url
        prefix_url
        specific_url
        name
        wniosker_id
      }
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allMysqlLocalfirms(
      filter: {
        url: { eq: $url }
        type: { eq: 3 }
        parentId: { eq: 0 }
        isHeadquarters: { eq: 0 }
      }
    ) {
      group(field: address_city, limit: 1) {
        nodes {
          address_city
          url
          geo_url
        }
      }
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "bank" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }
    bankPromotions: allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }, terms_path: {regex: $promoUrl}}) {
      totalCount
    }
    allSitePage(filter: {component: {glob: "**/bank-promo-list.js"}}) {
      nodes {
        path
      }
    }
    allMysqlComments(filter: {type: {eq: "bank"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "bank" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;
